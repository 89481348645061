import he from 'he';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (object: any = {}): any => {
  const decodedValue = he
    .decode(object)
    .trim()
    .replace(/(\\n|\\)/g, '');

  return (decodedValue && JSON.parse(decodedValue)) || {};
};
