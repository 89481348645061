import loadable from '@loadable/component';

import { getRewrittenUrl } from '@fie/analytics-library';

import { getHawk } from '../../../utils/global';
import type { BattleTest } from '../../types/Battle';
import type { ModelExtra, ReviewData } from '../../types/ModelInfo';
import type { Site } from '../sites/types/Site';

const lazyGetCustomTrackingIdServerside = loadable.lib(
  () => import('@fie/analytics-library/build/getCustomTrackingIdServerside'),
);

const lazyGetCustomTrackingId = loadable.lib(
  () => import('@fie/analytics-library/build/getCustomTrackingId'),
);

export default async (
  modelInfo: ModelExtra,
  showReviews: string,
  site: Site,
  area: string,
  articleUrl: string,
  battle: BattleTest | string | null,
): Promise<ReviewData[]> => {
  modelInfo = modelInfo || {};
  const reviews = Object.keys(modelInfo.reviews || {}).map((key) => modelInfo.reviews[key]);

  // Ignore reviews that don't have a reviews url HAWK-6131
  if (reviews) {
    const priorities = ((): string[] => {
      const defaultPriorities = ['asin', 'walmart'];
      const streamingScores = ['imdb', 'rt'];

      switch (showReviews) {
        case 'asin':
        case 'walmart':
          return [showReviews];
        case 'none':
          return [];
        case 'reversePriority':
          return defaultPriorities.reverse();
        case 'streamingScores':
          return streamingScores;
        default:
          return defaultPriorities;
      }
    })();
    const max = ((): number => {
      switch (showReviews) {
        case 'priority':
        case 'reversePriority':
          return 1;
        default:
          return priorities.length;
      }
    })();
    const { customTrackingId: forcedCustomTrackingId } = getHawk();
    const result: ReviewData[] = [];

    for (let i = 0; i < priorities.length; i++) {
      if (result.length < max) {
        /* Find the review and require the url, unless these are
        streaming reviews as we don't have a url */
        const review = reviews.find((review) => {
          if (['imdb', 'rt'].includes(review.code_name)) {
            return review.code_name === priorities[i];
          }
          return review.code_name === priorities[i] && review.reviews_url;
        });
        if (review) {
          let customTrackingId: string;
          if (process.env.IS_SERVERSIDE) {
            const { getCustomTrackingIdServerside } =
              await lazyGetCustomTrackingIdServerside.load();
            customTrackingId = getCustomTrackingIdServerside(
              {
                pageUrl: articleUrl,
                site,
                review,
                area,
                battle,
              },
              forcedCustomTrackingId,
            );
          } else {
            const { getCustomTrackingId } = await lazyGetCustomTrackingId.load();
            customTrackingId = await getCustomTrackingId(
              {
                pageUrl: articleUrl,
                site,
                review,
                area,
                battle,
              },
              forcedCustomTrackingId,
            );
          }

          review.reviews_customTrackingId = customTrackingId;

          result.push({
            ...review,
            reviews_url: getRewrittenUrl(
              review.reviews_url,
              site && site.analyticsName,
              area,
              customTrackingId,
            ),
          });
        }
      }
    }

    return result;
  }
  return [];
};
